<template>
    <div>
        <div class="mb-4">
            <h4 class="font-weight-bold">IDENTITAS PENANGGUNG JAWAB</h4>
            <em>
                <b>Penanggung Jawab</b> adalah orang yang ditunjuk Badan Usaha
                dengan bukti
                <b
                    >Surat Ketetapan atau Surat Penunjukkan sebagai Penanggung
                    Jawab penggunaan Aplikasi Retribusi Daerah Sumatera Utara</b
                >. Penanggung Jawab juga dapat diisi oleh Kepala Keuangan atau
                orang yang bertanggung jawab sebagai pengelola keuangan dan
                retribusi dalam suatu Badan Usaha.
            </em>
        </div>
        <div>
            <b-form
            >
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >NIK
                    </label>
                    <div class="col-md-6">
                        <input
                            type="text"
                            :class="{
                                'form-control': true,
                            }"
                            v-model="form.nik"
                            placeholder="Masukkan NIK"
                            disabled
                        />
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Nama
                    </label>
                    <div class="col-md-6">
                        <input
                            type="text"
                            :class="{
                                'form-control': true,
                            }"
                            v-model="form.nama"
                            placeholder="Masukkan Nama"
                            style="text-transform: uppercase;"
                            disabled
                        />
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Jabatan
                    </label>
                    <div class="col-md-6">
                        <input
                            type="text"
                            :class="{
                                'form-control': true,
                            }"
                            v-model="form.jabatan"
                            placeholder="Masukkan Jabatan"
                            disabled
                        />
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >No. Surat Ketetapan
                    </label>
                    <div class="col-md-6">
                        <input
                            type="text"
                            :class="{
                                'form-control': true,
                            }"
                            v-model="form.no_sk"
                            placeholder="Masukkan Nomor Surat Ketetapan"
                            disabled
                        />
                    </div>
                </div>
                <div class="form-group row mb-2">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Tgl. Surat Ketetapan
                    </label>
                    <div class="col-md-6">
                        <div class="input-group p-0 col-md-5">
                            <date-picker
                                id="dateTimeInput"
                                :class="{
                                    'form-control': true,
                                }"
                                v-model="form.tgl_sk"
                                :config="options"
                                placeholder="-Pilih Tanggal-"
                                disabled
                            ></date-picker>
                            <div class="input-group-append">
                                <label
                                    for="dateTimeInput"
                                    class="input-group-text"
                                >
                                    <i class="fa fa-calendar"></i>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row mb-2">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Dokumen SK
                    </label>
                    <div class="col-md-2">
                        <b-button
                            block
                            variant="outline-danger"
                        >
                            <i class="fa fa-file-pdf"></i> Lihat Dokumen SK
                        </b-button>
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Status Surat Ketetapan
                    </label>
                    <div class="col-md-6">
                        <v-select
                            label="nama"
                            :options="statusAktifOptions"
                            :reduce="nama => nama.id"
                            placeholder="Pilih Status Aktif"
                            v-model="form.aktif"
                            class="col-md-5 p-0"
                            disabled
                        >
                        </v-select> 
                        <div class="col-md-12">
                            <div class="row font-weight-bold text-danger">
                                Penting:
                            </div>
                            <ol class="p-0" style="line-height: 1.2em">
                                <li>
                                    Dokumen Surat Ketetapan (SK) dapat berupa
                                    Akta Pendirian (pada halaman Cover dan
                                    halaman yang menunjukkan bukti sebagai
                                    Pimpinan Badan Usaha).
                                </li>
                                <li>
                                    Atau Surat Ketetapan yang laingnya yang
                                    membuktikan sebagai Pimpinan Badan Usaha.
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div class="mt-3 mb-2">
                    <h4>KONTAK & ALAMAT</h4>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >No. Handphone
                    </label>
                    <div class="col-md-4">
                        <input
                            type="text"
                            :class="{
                                'form-control': true,
                            }"
                            v-model="form.nomp"
                            placeholder="Masukkan Nomor Handphone"
                            disabled
                        />
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >No. Telepon
                    </label>
                    <div class="col-md-4">
                        <input
                            type="text"
                            :class="{
                                'form-control': true,
                            }"
                            v-model="form.notelepon"
                            placeholder="Masukkan Nomor Telepon"
                            disabled
                        />
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Email
                    </label>
                    <div class="col-md-6">
                        <input
                            type="email"
                            :class="{
                                'form-control': true,
                            }"
                            v-model="form.email"
                            placeholder="Masukkan Alamat Email"
                            disabled
                        />
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Alamat
                    </label>
                    <div class="col-md-6">
                        <input
                            type="text"
                            class="form-control"
                            placeholder="Masukkan Alamat"
                            v-model="form.alamat"
                            disabled
                        />
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Provinsi
                    </label>
                    <div class="col-md-4">
                        <v-select
                            label="nama"
                            :options="WilayahIndonesia.provinsi"
                            :reduce="(nama) => nama.id"
                            v-model="input.provinsi"
                            placeholder="Pilih Provinsi"
                            disabled
                        >
                        </v-select> 
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Kota/Kabupaten
                    </label>
                    <div class="col-md-4">
                        <v-select
                            label="nama"
                            :options="WilayahIndonesia.kabkota"
                            :reduce="nama => nama.id"
                            placeholder="Pilih Kabupaten/Kota"
                            v-model="input.kabkota"
                            class="bg-white"
                            disabled
                        ></v-select>
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Kecamatan
                    </label>
                    <div class="col-md-4">
                        <v-select
                            label="nama"
                            :options="WilayahIndonesia.kecamatan"
                            :reduce= "nama => nama.id"
                            v-model="input.kecamatan"
                            placeholder="Pilih Kecamatan"
                            disabled
                        ></v-select>
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Kelurahan
                    </label>
                    <div class="col-md-4">
                        <v-select
                            label="nama"
                            :options="WilayahIndonesia.kelurahan"
                            :reduce="nama => nama.id"
                            v-model="form.wilayah_kelurahan_id"
                            placeholder="Pilih Kelurahan"
                            class="bg-white"
                            disabled
                        ></v-select>
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Kodepos
                    </label>
                    <div class="col-md-4">
                        <input
                            type="text"
                            :class="{
                                'form-control': true,
                            }"
                            v-model="form.kodepos"
                            placeholder="Masukkan Kodepos"
                            disabled
                        />
                    </div>
                </div>
            </b-form>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
// eslint-disable-next-line
// eslint-disable vue/no-unused-vars
// eslint-disable-line no-unused-vars
import { mapState } from 'vuex'
import axios from 'axios'
import hapusStorage from '@/helper/hapusLocalStore'

export default {
    mixins: [hapusStorage],
    data () {
        return {
            form: new Form({
                nik: "",
                npwpd_id: "",
                nama: "",
                nomp: "",
                notelepon: "",
                email: "",
                alamat: "",
                wilayah_kelurahan_id: "",
                kodepos: "",
                jabatan: "",
                no_sk: "",
                tgl_sk: "",
                dok_sk: null,
                status_sk: 2,
                aktif: ''
            }),
            input: {
                provinsi: "",
                kabkota: "",
                kecamatan: ""
            },
            options: {
                format: "YYYY-MM-DD",
                useCurrent: false,
            },
            statusAktifOptions: [
                {
                    id: 0,
                    nama: "Non Aktif",
                },
                {
                    id: 1,
                    nama: "Aktif",
                },
            ],
        }
    },
    computed: {
        ...mapState([
            "WilayahIndonesia",
            "cekNpwrd"
        ])
    },
    mounted () {
        this.dataPenanggungJawab(this.cekNpwrd.detailNpwrd.penanggung_jawab.id)
    },
    methods: {
      // on input provinsi
        inputProvinsi(id) {
           this.$store.dispatch('WilayahIndonesia/getKabkota', id)
        },
        inputKabkota(id) {
           this.$store.dispatch('WilayahIndonesia/getKecamatan', id)
        },
        inputKecamatan(id) {
           this.$store.dispatch('WilayahIndonesia/getKelurahan', id)
        },
        // data penanggung jawab
        dataPenanggungJawab (id) {
            axios.get("/api/wajib-retribusi/npwrd-wakil/" + id).then(response => {
                const items = response.data.data
                // console.log(items)
                this.form.fill(items)
                if (items.wilayah_kelurahan) {
                    this.input.provinsi = items.wilayah_kelurahan.wilayah_kecamatan.wilayah_kabkota.wilayah_provinsi_id
                    this.inputProvinsi(this.input.provinsi)
                    this.input.kabkota = items.wilayah_kelurahan.wilayah_kecamatan.wilayah_kabkota_id
                    this.inputKabkota(this.input.kabkota)
                    this.input.kecamatan = items.wilayah_kelurahan.wilayah_kecamatan_id
                    this.inputKecamatan(this.input.kecamatan)
                }
            }).catch(error => {
                console.log(error)
                if (error.response.status === 401) {
                    // hapus storage mixins
                    this.clearAll()
                }
            })
        }
  }
}
</script>
