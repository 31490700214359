<template>
    <div>
        <div class="row container pb-3">
            <div class="col-sm-6"></div>
            <div class="col-sm-6">
                <h4 class="float-right">
                    <i class="fa fa-info-circle"></i> Step 1 - DATA WAJIB
                    RETRIBUSI
                </h4>
            </div>
        </div>
        <div>
            <b-card class="col-md-12 my-3" v-if="editMode">
                <b-row>
                    <b-col>
                        <label for="Nomor Pokok"
                            >Cek Berdasarkan Nomor Pokok Wajib Retribusi
                            Daerah:</label
                        >
                        <b-input-group>
                            <b-form-input
                                v-model="no_pokok"
                                placeholder="Masukkan Nomor Pokok"
                            ></b-form-input>
                            <b-input-group-append>
                                <b-button
                                    variant="info"
                                    @click="checkNpwrd(no_pokok, 'pokok')"
                                    :disabled="cekNpwrd.isLoading"
                                >
                                    <span v-if="cekNpwrd.isLoading">
                                        <b-spinner
                                            variant="light"
                                            label="Spinning"
                                            small
                                        ></b-spinner>
                                        <span class="text-light">
                                            Memuat...
                                        </span>
                                    </span>
                                    <span v-else>
                                        Cek NPWRD
                                    </span>
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                        <small
                            class="text-danger font-weight-bold"
                            v-if="cekNpwrd.dataExist"
                        >
                            Data NPWRD Tidak Ditemukan.
                        </small>
                    </b-col>
                    <b-col>
                        <label for="Nomor Induk"
                            >Cek Berdasarkan Nomor Induk Kependudukan
                            (Perorangan) atau Nomor Induk Berusaha (Badan Usaha)
                            :</label
                        >
                        <b-input-group>
                            <b-form-input
                                v-model="no_induk"
                                placeholder="Masukkan Nomor Induk"
                            ></b-form-input>
                            <b-input-group-append>
                                <b-button
                                    variant="warning"
                                    @click="checkNpwrd(no_induk, 'induk')"
                                    :disabled="cekNpwrd.isLoading"
                                >
                                    <span v-if="cekNpwrd.isLoading2">
                                        <b-spinner
                                            variant="light"
                                            label="Spinning"
                                            small
                                        ></b-spinner>
                                        <span class="text-light">
                                            Memuat...
                                        </span>
                                    </span>
                                    <span v-else>
                                        Cek NPWRD
                                    </span>
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                        <small
                            class="text-danger font-weight-bold"
                            v-if="cekNpwrd.dataExist2"
                        >
                            Data NPWRD Tidak Ditemukan.
                        </small>
                    </b-col>
                </b-row>
            </b-card>

            <b-card class="mb-3">
                <div class="nav-wizards-container">
                    <nav class="nav nav-wizards-2">
                        <!-- completed -->
                        <div class="nav-item col">
                            <a
                                :class="{
                                    'nav-link': true,
                                    active: isActive
                                }"
                                @click="tabButton(true)"
                                style="cursor: pointer;"
                            >
                                <div class="nav-text">
                                    IDENTITAS WAJIB RETRIBUSI DAERAH
                                </div>
                            </a>
                        </div>

                        <!-- active -->
                        <div class="nav-item col">
                            <a
                                :class="{
                                    'nav-link': true,
                                    active: isActive2,
                                    disabled: Pendaftaran.disableBentuk
                                }"
                                @click="tabButton(false)"
                                style="cursor: pointer;"
                            >
                                <div class="nav-text">
                                    PENANGGUNG JAWAB
                                </div>
                            </a>
                        </div>
                    </nav>
                </div>
            </b-card>

            <div class="card">
                <div
                    v-if="cekNpwrd.isLoading || cekNpwrd.isLoading2"
                    class="text-center"
                    style="height: 100vh; margin-top: 20%"
                >
                    <b-spinner variant="primary" label="Spinning"></b-spinner>
                    <div class="text-primary">
                        Memuat...
                    </div>
                </div>
                <div v-else>
                    <div class="card-body" v-if="wajib">
                        <WRIndividu />
                    </div>
                    <div class="card-body" v-else>
                        <WRBadanUsaha />
                    </div>
                </div>
            </div>
            <div class="my-3">
                <span class="float-right">
                    <div>
                        <b-button
                            variant="success"
                            @click="nextButton"
                            :disabled="cekNpwrd.next"
                        >
                            <i class="fa fa-arrow-circle-right"></i>
                            Selanjutnya
                        </b-button>
                    </div>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
// eslint-disable-next-line
// eslint-disable vue/no-unused-vars
// eslint-disable-line no-unused-vars
import WRIndividu from "@/components/npwr/WRIndividu.vue";
import WRBadanUsaha from "@/components/npwr/WRBadanUsaha.vue";
import dateFormat from "@/helper/dateFormat";
import { mapState } from "vuex";
import detailSptMixin from "@/helper/api/sptDetail.js";

export default {
    mixins: [dateFormat, detailSptMixin],
    components: {
        WRIndividu,
        WRBadanUsaha
    },
    data() {
        return {
            isActive: true,
            isActive2: false,
            cek: {
                no_pokok: null,
                isShow: false,
                isShow2: false,
                isShow3: false,
                nextDisabled: true
            },
            idNpwrd: "",
            no_pokok: JSON.parse(localStorage.getItem("no_pokok")),
            storeButton: false,
            wajib: true,
            no_induk: JSON.parse(localStorage.getItem("no_induk")),
            editMode: true
        };
    },
    computed: {
        ...mapState(["BentukBadan", "Jabatan", "cekNpwrd", "Pendaftaran"])
    },
    mounted() {
        this.$store.dispatch("WilayahIndonesia/getProvinsi");
        if (this.$route.name === "NextStep1") {
            this.dataSpt(this.$route.params.id);
        } else {
            this.editMode = true;
            if (
                this.cekNpwrd.prevMode ||
                JSON.parse(localStorage.getItem("npwrdId"))
            ) {
                if (this.no_pokok) {
                    this.checkNpwrd(this.no_pokok, "pokok");
                } else if (this.no_induk) {
                    this.checkNpwrd(this.no_induk, "induk");
                }
            } else {
                if (this.cekNpwrd.dataNpwrd.length === 0) {
                    this.no_pokok = "";
                    this.no_induk = "";
                }
            }
        }
    },
    methods: {
        // cek nomor pokok
        checkNpwrd(nomor, value) {
            if (value == "pokok") {
                this.$store.dispatch("cekNpwrd/detail", nomor);
            } else if (value == "induk") {
                this.$store.dispatch("cekNpwrd/detailInduk", nomor);
            }
        },
        // tombol next
        nextButton() {
            localStorage.setItem("npwrdId", this.cekNpwrd.detailNpwrd.id);
            if (this.$route.name === "NextStep1") {
                this.$router.push({
                    name: "NextStep2",
                    params: { id: this.$route.params.id }
                });
            } else {
                this.$router.push({ name: "Step2" });
                if (this.no_pokok) {
                    localStorage.setItem("no_pokok", this.no_pokok);
                } else if (this.no_induk) {
                    localStorage.setItem("no_induk", this.no_induk);
                }
            }
        },

        tabButton(bol) {
            if (bol) {
                this.wajib = bol;
                this.isActive2 = false;
            } else {
                this.wajib = bol;
                this.isActive2 = true;
            }
        }
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit("cekNpwrd/dataNpwrd", []);
        this.$store.commit("cekNpwrd/existNpwrd", {
            bol: false,
            name: "pokok"
        });
        this.$store.commit("cekNpwrd/existNpwrd", {
            bol: false,
            name: "induk"
        });
        this.$store.commit("cekNpwrd/isNext", true);
        localStorage.setItem("no_pokok", null);
        localStorage.setItem("no_induk", null);
        next();
    },
    beforeRouteEnter(to, from, next) {
        localStorage.setItem("no_pokok", null);
        localStorage.setItem("no_induk", null);
        localStorage.setItem("sptId", null);
        next();
    }
};
</script>
