<template>
    <div>
        <div class="mb-4">
            <h4 class="font-weight-bold">IDENTITAS WAJIB RETRIBUSI DAERAH</h4>
            <em>
                Wajib Retribusi Daerah adalah <b>Perorangan</b> atau
                <b> Badan Usaha </b> yang memiliki kewajiban retribusi di daerah
                Provinsi Sumatera Utara.
            </em>
        </div>
        <div>
            <b-form>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Jenis
                    </label>
                    <div class="col-md-4">
                        <v-select
                            :options="['Perorangan', 'Badan Usaha']"
                            v-model="form.jenis"
                            placeholder="Pilih Jenis"
                            class="bg-white"
                            disabled
                        >
                        </v-select>
                    </div>
                </div>
                <div class="form-group row mb-1" v-if="disableBentuk">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Bentuk Badan
                    </label>
                    <div class="col-md-4">
                        <v-select
                            label="nama"
                            :options="BentukBadan.bentuk_badan"
                            :reduce="(nama) => nama.id"
                            v-model="form.bentuk_badan_id"
                            placeholder="Pilih Bentuk Badan"
                            class="bg-white"
                            disabled
                        >
                        </v-select>
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        ><span v-if="disableBentuk">
                            NIB
                        </span>
                        <span v-else>
                            NIK
                        </span>
                    </label>
                    <div class="col-md-6">
                        <input
                            type="text"
                            v-model="form.no_induk"
                            :class="{
                                'form-control': true,
                            }"
                            placeholder="Masukkan NIK"
                            disabled
                        />
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Nama Wajib Retribusi
                    </label>
                    <div class="col-md-6">
                        <input
                            type="text"
                            :class="{
                                'form-control': true,
                            }"
                            v-model="form.nama"
                            placeholder="Masukkan Nama"
                            style="text-transform: uppercase;"
                            disabled
                        />
                    </div>
                </div>
                <div class="pt-5">
                    <h4 class="font-weight-bold">KONTAK & ALAMAT</h4>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Nomor HP
                    </label>
                    <div class="col-md-4">
                        <input
                            type="text"
                            :class="{
                                'form-control': true,
                            }"
                            v-model="form.nomp"
                            placeholder="Masukkan Nomor Handphone"
                            disabled
                        />
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Nomor Telepon
                    </label>
                    <div class="col-md-4">
                        <input
                            type="text"
                            :class="{
                                'form-control': true,
                            }"
                            v-model="form.notelepon"
                            placeholder="Masukkan Nomor Telepon"
                            disabled
                        />
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Email
                    </label>
                    <div class="col-md-6">
                        <input
                            type="email"
                            :class="{
                                'form-control': true,
                            }"
                            v-model="form.email"
                            placeholder="Masukkan Alamat Email"
                            disabled
                        />
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Alamat
                    </label>
                    <div class="col-md-6">
                        <input
                            type="text"
                            :class="{
                                'form-control': true,
                            }"
                            v-model="form.alamat"
                            placeholder="Masukkan Alamat"
                            disabled
                        />
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Provinsi
                    </label>
                    <div class="col-md-4">
                        <v-select
                            label="nama"
                            :options="WilayahIndonesia.provinsi"
                            :reduce="(nama) => nama.id"
                            v-model="wilayah.provinsi"
                            @input="inputProvinsi(wilayah.provinsi)"
                            placeholder="Pilih Provinsi"
                            disabled
                        >
                        </v-select>
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Kota/Kabupaten
                    </label>
                    <div class="col-md-4">
                        <v-select
                            label="nama"
                            :options="WilayahIndonesia.kabkota"
                            :reduce="(nama) => nama.id"
                            v-model="wilayah.kabkota"
                            @input="inputKabkota(wilayah.kabkota)"
                            placeholder="Pilih Kabupaten/Kota"
                            class="bg-white"
                            disabled
                        ></v-select>
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Kecamatan
                    </label>
                    <div class="col-md-4">
                        <v-select
                            label="nama"
                            :options="WilayahIndonesia.kecamatan"
                            :reduce="(nama) => nama.id"
                            v-model="wilayah.kecamatan"
                            @input="inputKecamatan(wilayah.kecamatan)"
                            placeholder="Pilih Kecamatan"
                            disabled
                        ></v-select>
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Kelurahan
                    </label>
                    <div class="col-md-4">
                        <v-select
                            label="nama"
                            :options="WilayahIndonesia.kelurahan"
                            :reduce="nama => nama.id"
                            v-model="form.wilayah_kelurahan_id"
                            placeholder="Pilih Kelurahan"
                            class="bg-white"
                            disabled
                        ></v-select>
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Kodepos
                    </label>
                    <div class="col-md-4">
                        <input
                            type="text"
                            :class="{
                                'form-control': true,
                            }"
                            v-model="form.kodepos"
                            placeholder="Masukkan Kodepos"
                            disabled
                        />
                    </div>
                </div>
            </b-form>
        </div>
        {{ coba }}
    </div>
</template>

<script>
/* eslint-disable */
// eslint-disable-next-line
// eslint-disable vue/no-unused-vars
// eslint-disable-line no-unused-vars
import { mapState } from "vuex";

export default {
    props: ["disabled"],
    data() {
        return {
            form: new Form({
                nama: "",
                no_induk: "",
                alamat: "",
                notelepon: "",
                nomp: "",
                email: "",
                bentuk_badan_id: "",
                status_registrasi_id: "",
                ket_status: "",
                jenis: "",
                kodepos: "",
                wilayah_kelurahan_id: "",
            }),
            wilayah: {
                provinsi: "",
                kabkota: "",
                kecamatan: "",
            },
            editMode: false,
            isLoading: false,
            disableBentuk: false,
        };
    },
    computed: {
        ...mapState(["WilayahIndonesia", "BentukBadan", "cekNpwrd"]),
        coba: function() {
            this.detailWatcher()
        }
    },
    mounted() {
        this.$store.dispatch("BentukBadan/bentukBadan")
    },
    methods: {
        detailWatcher () {
            let dataNpwrd = this.cekNpwrd.detailNpwrd
            this.form.fill(this.cekNpwrd.detailNpwrd)
            if (this.form.jenis < 41) {
                this.form.jenis = "Badan Usaha"
                this.disableBentuk = true
                this.$store.dispatch("Pendaftaran/isDisableBentuk", false)
            } else if (this.form.jenis > 41) {
                this.form.jenis = "Perorangan"
                this.disableBentuk = false
                this.$store.dispatch("Pendaftaran/isDisableBentuk", true)
            }
            if (dataNpwrd.wilayah_kelurahan) {
                this.wilayah.provinsi = dataNpwrd.wilayah_kelurahan.wilayah_kecamatan.wilayah_kabkota.wilayah_provinsi_id
                this.inputProvinsi(this.wilayah.provinsi)
                this.wilayah.kabkota = dataNpwrd.wilayah_kelurahan.wilayah_kecamatan.wilayah_kabkota_id
                this.inputKabkota(this.wilayah.kabkota)
                this.wilayah.kecamatan = dataNpwrd.wilayah_kelurahan.wilayah_kecamatan_id
                this.inputKecamatan(this.wilayah.kecamatan)
                this.form.wilayah_kelurahan_id = parseInt(this.form.wilayah_kelurahan_id)
            }
        },
        // on input provinsi
        inputProvinsi(id) {
            if (this.wilayah.provinsi === null) {
                this.$store.dispatch("WilayahIndonesia/kabkota", []);
                this.$store.dispatch("WilayahIndonesia/kecamatan", []);
                this.wilayah.kabkota = "";
                this.wilayah.kecamatan = "";
                this.form.wilayah_kelurahan_id = ""
            } else {
                this.$store.dispatch("WilayahIndonesia/getKabkota", id);
            }
        },
        // on input kabkota
        inputKabkota(id) {
            if (this.wilayah.kabkota === null) {
                this.$store.dispatch("WilayahIndonesia/kecamatan", [])
                this.wilayah.kecamatan = "";
                this.form.wilayah_kelurahan_id = ""
            } else {
                this.$store.dispatch("WilayahIndonesia/getKecamatan", id);
            }
        },
        // on input kecamatan
        inputKecamatan(id) {
            this.$store.dispatch("WilayahIndonesia/getKelurahan", id);
        }
    },
};
</script>
